import React from 'react';

export class MediaObject extends React.Component {
  render () {
    if (!(this.props.media.file && this.props.media.file)) return null;
    if (this.props.media.file.url.indexOf('.mp4') > -1) return this.renderVideo();
    return this.renderImage();
  }

  renderImage () {
    return <img className="media-object" src={this.props.media.file.url + '?fm=jpg&w=1200'} alt={this.props.media.description} />;
  }

  renderVideo () {
    return <video className="media-object" src={this.props.media.file.url} controls />;
  }
}

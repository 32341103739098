import React from 'react';
import { MediaObject } from '../media-object';

export class Gallery extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  render () {
    return (this.props.photos && this.props.photos.length > 0) ? (
      <div className="gallery">
        { this.props.photos.map(photo => <div><MediaObject media={photo} /></div>) }
      </div>
    ) : <div/>;
  }
}

import React from 'react';
import { PageColor } from '../components/page-color';
import { Content } from '../components/content';
import { Gallery } from '../components/gallery';
import { Link } from 'gatsby';

export default class Jobs extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get website () {
    return this.props.pageContext.website;
  }

  get jobs () {
    return this.website.vacatures;
  }

  render = () => (
    <main className="case">
      <PageColor color="terracotta" nav="pigeon" />
      <div className="case__left">
        <div className="case__left__image">
          <img src={this.website.vacaturesImage.file.url + '?fm=jpg&w=1200'} />
        </div>
      </div>
      <div className="case__right">
        <div className="case__content">
          { this.jobs.map(job => {
            return (
              <div className="job-overview">
                <h1>
                  <div className="title-poppins">{ job.title }</div>
                  <div className="title-ivy">{ job.subtitle }</div>
                </h1>

                <div className="case__content">{job.intro}</div>
                <div className="case__content job-overview__link"><Link to={job.slug}>read more...</Link></div>
              </div>
            )
          }) }
        </div>
      </div>
    </main>
  );
}
